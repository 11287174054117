<template>
  <item-page :item="ingredient" v-if="ingredient" type="ingredient"></item-page>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["id"],
  inject: ["activePage"],
  data() {
    return {
      ingredient: null,
    };
  },
  computed: {
    ...mapGetters(["getIngredient"]),
  },
  methods: {
    ...mapActions(["loadIngredient"]),
    async updateItem() {
      await this.loadIngredient(this.id);
      const item = this.getIngredient;
      console.log(item);
      if (!item) {
        console.log("no item found");
        this.$router.replace("/ingredients");
        return;
      }
      this.ingredient = item;
    },
  },
  created() {
    this.activePage.value = this.$routeNames.routeIngredientPage;
    this.updateItem();
  },
};
</script>

<style scoped>
</style>
